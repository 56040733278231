<template>
	<div>
		<CCard class="p-4">
			<form @submit.prevent="submit">
		<CRow>
			
			<CCol lg="6">
					<h6>Assign Permission - {{dataParams.setting_name}}</h6>
			</CCol> 
			<CCol lg="6">
				<div style="text-align: right">
					<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>		
					<CButton  size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="backToTable()">
	                    Cancel
	                </CButton>
	                
				</div>
			</CCol>
		</CRow>
		<hr/>
		
		
			<CRow>
				<CCol lg="12">
					<h6>Accounting Management</h6>
				</CCol>
			</CRow>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Payroll
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('payroll')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('payroll')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<!-- <CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('payroll')].update" 
				                    :inline="true" 
			                    />  
							</CCol> -->
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('payroll')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('payroll')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox :label="'Print'"
									:checked.sync="dataParams.permission[getIndex('payroll')].print" 
				                    :inline="true" 
			                    />  
							</CCol> 
							
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						13th Month
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('13th_month')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('13th_month')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('13th_month')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('13th_month')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Print'"
									:checked.sync="dataParams.permission[getIndex('13th_month')].print" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Personnel Ledger
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('personnel_ledger')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('personnel_ledger')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('personnel_ledger')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('personnel_ledger')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('personnel_ledger')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Adjustment
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('adjustment')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('adjustment')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('adjustment')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('adjustment')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('adjustment')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Working Calendar
					</CCol>
					<CCol lg="9">
						<CRow>
							<!-- <CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('working_calendar')].view" 
				                    :inline="true" 
			                    />  
							</CCol> -->
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('working_calendar')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Overtime / Late
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('ot_late')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('ot_late')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('ot_late')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('ot_late')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('ot_late')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						SSS Table
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('sss')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('sss')].download" 
				                    :inline="true" 
			                    />  
							</CCol>
							
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
						<CCol lg="12">
							<h6>Asset Management</h6>
						</CCol>
				</CRow>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Equipment
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('equipment')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('equipment')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('equipment')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('equipment')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<!-- <CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('equipment')].download" 
				                    :inline="true" 
			                    />  
							</CCol>  -->
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Furnishing
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('furnishing')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('furnishing')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('furnishing')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('furnishing')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
						
						</CRow>
					</CCol>
				</CRow>
				<br/>

			<CRow>
				<CCol lg="12">
					<h6>Billing Management</h6>
				</CCol>
			</CRow>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Client Billing
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('client billing')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('client billing')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('client billing')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('client billing')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('client billing')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Trucker Settlement
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('trucker_settlement')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('trucker_settlement')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('trucker_settlement')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('trucker_settlement')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('trucker_settlement')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
						<CCol lg="12">
							<h6>Bookings Management</h6>
						</CCol>
				</CRow>
				
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Bookings
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('booking')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('booking')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('booking')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('booking')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Print'"
									:checked.sync="dataParams.permission[getIndex('booking')].print" 
				                    :inline="true" 
			                    /> 
							</CCol>
						</CRow>
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Deliver'"
									:checked.sync="dataParams.permission[getIndex('booking')].deliver" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Complete'"
									:checked.sync="dataParams.permission[getIndex('booking')].complete" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Dispatch'"
									:checked.sync="dataParams.permission[getIndex('booking')].dispatch" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Approve'"
									:checked.sync="dataParams.permission[getIndex('booking')].approve" 
				                    :inline="true" 
			                    /> 
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Cancel'"
									:checked.sync="dataParams.permission[getIndex('booking')].cancel" 
				                    :inline="true" 
			                    /> 
							</CCol>
						</CRow>
					</CCol>
				</CRow>
				<br/>

						<CRow>
						<CCol lg="12">
							<h6>Data Management</h6>
						</CCol>
					</CRow>
					<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Vehicles
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('vehicle')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('vehicle')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('vehicle')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('vehicle')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<!-- <CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('vehicle')].download" 
				                    :inline="true" 
			                    />  
							</CCol>  -->
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Company
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('company')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('company')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('company')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('company')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<!-- <CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('vehicle')].download" 
				                    :inline="true" 
			                    />  
							</CCol>  -->
						</CRow>
					</CCol>
				</CRow>
			<br/>
				
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Personnel
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('personnel')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('personnel')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('personnel')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('personnel')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('personnel')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Users
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('user')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('user')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('user')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('user')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('user')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Customers
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('customers')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('customers')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('customers')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('customers')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('customers')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Vendors
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('vendor')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('vendor')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('vendor')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('vendor')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('vendor')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Client Trucker Rates
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('client_trucker_rates')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('client_trucker_rates')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('client_trucker_rates')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('client_trucker_rates')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('client_trucker_rates')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Personnel Rates
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('personnel_rates')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('personnel_rates')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('personnel_rates')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('personnel_rates')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('personnel_rates')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
						<CCol lg="12">
							<h6>Fuel Records Management</h6>
						</CCol>
				</CRow>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Fuel Record
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('fuel_record')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('fuel_record')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('fuel_record')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('fuel_record')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>  
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('fuel_record')].download" 
				                    :inline="true" 
			                    /> 
							</CCol>  
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
						<CCol lg="12">
							<h6>Incident Management</h6>
						</CCol>
				</CRow>
				
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Incidents
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('incident')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('incident')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('incident')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('incident')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('incident')].download" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
						<CCol lg="12">
							<h6>Inventory Management</h6>
						</CCol>
				</CRow>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Parts Inventory
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('part_inventory')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('part_inventory')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('part_inventory')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('part_inventory')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>  
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Purchasing
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('purchasing')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('purchasing')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('purchasing')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('purchasing')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>  
						</CRow>
					</CCol>
				</CRow>
				<br/>
			
				
					
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Material Transfer Slip
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('mts')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('mts')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('mts')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('mts')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Print'"
									:checked.sync="dataParams.permission[getIndex('mts')].print" 
				                    :inline="true" 
			                    /> 
							</CCol>
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Delivery Receipt
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('dr')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('dr')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('dr')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('dr')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Print'"
									:checked.sync="dataParams.permission[getIndex('dr')].print" 
				                    :inline="true" 
			                    /> 
							</CCol>
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Booking Charges
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('booking_charges')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('booking_charges')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('booking_charges')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('booking_charges')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Booking Expenses
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('booking_expenses')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('booking_expenses')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
						</CRow>
						
					</CCol>
				</CRow>
				<br/>
				
				
						<CRow>
						<CCol lg="12">
							<h6>Settings
								
							</h6>
						</CCol>
					</CRow>
					<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Booking Type
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('booking_type')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('booking_type')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('booking_type')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
						
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Commodity Type
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('commodity_type')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('commodity_type')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('commodity_type')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Depot
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('depot')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('depot')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('depot')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Destination
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('destination')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('destination')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('destination')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				
					
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Maker/Manufacturer
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('maker_model')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('maker_model')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('maker_model')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Origin
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('origin')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('origin')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('origin')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Part Categories
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('part_categories')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('part_categories')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('part_categories')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Part Locations
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('part_locations')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('part_locations')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('part_locations')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Personnel Type
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('personnel_type')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('personnel_type')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('personnel_type')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Renewal Tasks
					</CCol>
					<CCol lg="9">
						<CRow>
						
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('renewal_tasks')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('renewal_tasks')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('renewal_tasks')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
						
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						User Types
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('user_types')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('user_types')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('user_types')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
					<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Service Tasks
					</CCol>
					<CCol lg="9">
						<CRow>
						
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('service_tasks')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('service_tasks')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('service_tasks')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
						
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Vehicle Type
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('vehicle_type')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('vehicle_type')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('vehicle_type')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Vehicle Model
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('vehicle_model')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('vehicle_model')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('vehicle_model')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Vendor Type
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('vendor_type')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('vendor_type')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('vendor_type')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol> 
						
							
						</CRow>
					</CCol>
				</CRow>
				<br/>
				
			
				<CRow>
						<CCol lg="12">
							<h6>Reminders</h6>
						</CCol>
				</CRow>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Renewal Reminders
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('renewal_reminder')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('renewal_reminder')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('renewal_reminder')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('renewal_reminder')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>  
						</CRow>
					</CCol>
					
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Service Reminders
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('service_reminder')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('service_reminder')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('service_reminder')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('service_reminder')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>  
						</CRow>
					</CCol>
					
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Personnel Renewal Reminders
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('license_renewal')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('license_renewal')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('license_renewal')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('license_renewal')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>  
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('license_renewal')].download" 
				                    :inline="true" 
			                    /> 
							</CCol>  
						</CRow>
					</CCol>
					
				</CRow>
				<CRow>
						<CCol lg="12">
							<h6>Work Order</h6>
						</CCol>
				</CRow>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Work Order
					</CCol>
					<CCol lg="9">
						<CRow>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('work_order')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Create'"
									:checked.sync="dataParams.permission[getIndex('work_order')].create" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Update'"
									:checked.sync="dataParams.permission[getIndex('work_order')].update" 
				                    :inline="true" 
			                    />  
							</CCol>
							<CCol lg="2"> 
			                    <CInputCheckbox 
									:label="'Delete'"
									:checked.sync="dataParams.permission[getIndex('work_order')].delete" 
				                    :inline="true" 
			                    /> 
							</CCol>  
						</CRow>
					</CCol>
				</CRow>
				<br/>
			
							
				<CRow>
						<CCol lg="12">
							<h6>Reports</h6>
						</CCol>
				</CRow>
				
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Booking Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('booking_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('booking_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Vehicle Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('vehicle_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('vehicle_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Personnel Settlement Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('personnel_settlement_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('personnel_settlement_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Equipment Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('equipment_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('equipment_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Furnishing Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('furnishing_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('furnishing_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Work Order Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('workorder_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('workorder_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Delivery Receipt Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('dr_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('dr_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Material Transfer Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('mts_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('mts_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Booking Charges Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('booking_charges_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('booking_charges_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Opex Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('opex_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('opex_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				<CRow>
           			<CCol lg="1"></CCol>
					<CCol lg="2">
						Client Billing Summary Reports
					</CCol>
					<CCol lg="9">
						<CRow>
							<CCol lg="2">
								<CInputCheckbox 
									:label="'View'"
									:checked.sync="dataParams.permission[getIndex('client_billing_summary_reports')].view" 
				                    :inline="true" 
			                    />  
							</CCol>
							
							<CCol lg="2">
								<CInputCheckbox 
									:label="'Download'"
									:checked.sync="dataParams.permission[getIndex('client_billing_summary_reports')].download" 
				                    :inline="true" 
			                    />  
							</CCol> 
						</CRow>
					</CCol>
				</CRow>
				<br/>
				
			
	           
	            <!-- <div slot="footer" class="w-100">
	            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="">
	                    Cancel
	                </CButton>
	                <CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Okay
	                </CButton>
	                
	            </div> -->
           	</form>
         
		</CCard>
	</div>
</template>
<script>  
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';

export default {
	mounted(){
		console.log(config);
		this.getData();
	},
	data(){
		return{
			formModal: false,
			permissionModal: false,
			userTypeList: [],
			userTypeName: "",
			fields: [
				{
					key: 'setting_name', 
					label: 'Usertype'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,
			editMode: false,
			selectedRow: {},
			filterName: "",
			showCollapse: false,
			isLoading: false,
			dataParams: {
				setting_name: {},
				permission: [
					{
						module: "booking",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "booking_charges",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "booking_expenses",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "dr",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "mts",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "client billing",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
						{
						module: "trucker_settlement",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "personnel",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "user",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "company",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "vehicle",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "customers",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "vendor",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "personnel_rates",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "client_trucker_rates",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "incident",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "personnel_type",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "booking_type",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "renewal_tasks",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "service_tasks",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "vehicle_type",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "commodity_type",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "origin",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "destination",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "maker_model",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "vehicle_model",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					
					{
						module: "depot",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "vendor_type",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "part_categories",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "part_locations",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "user_types",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "booking_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "opex_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "vehicle_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "personnel_settlement_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "workorder_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "dr_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "mts_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "furnishing_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "equipment_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "booking_charges_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "client_billing_summary_reports",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "equipment",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "furnishing",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "work_order",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "part_inventory",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "purchasing",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "fuel_record",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "renewal_reminder",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "service_reminder",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "payroll",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "13th_month",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "personnel_ledger",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "adjustment",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "working_calendar",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "ot_late",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "sss",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
					{
						module: "license_renewal",
						view: false,
						create: false,
						update: false,
						delete: false,
						print: false,
						deliver: false,
						complete: false,
						dispatch: false,
						approve: false,
						cancel: false,
						download: false,
						usertype_id: this.$route.params.id
					},
				]

			}
		}
	},
	name: 'Tables', 
	methods: {
		 backToTable(){
	    	this.$router.push('/settings/user_type')
	    },

		getData(){
			axios.get(config.api_path+"/setting/"+this.$route.params.id)
			.then(response=>{
				if(response.data.data.permission.length > 0){
					this.dataParams.permission = this.dataParams.permission.map((value, index) => {
						var row = response.data.data.permission.filter((val, i)=>{
							console.log(value.module  +""+ val.module)
							return value.module  == val.module;
						})
						if(row.length > 0){
							value = row[0];
						}
						return value;
					})
					this.dataParams.permission = this.dataParams.permission.map((value, index)=>{
							value.approve = value.approve == 1 || value.approve == "1" ? true : false
							value.cancel = value.cancel == 1 || value.cancel == "1" ? true : false 
							value.complete = value.complete == 1 || value.complete == "1" ? true : false
							value.create = value.create == 1 || value.create == "1" ? true : false 
							value.delete = value.delete == 1 || value.delete == "1" ? true : false 
							value.deliver = value.deliver == 1 || value.deliver == "1" ? true : false
							value.dispatch = value.dispatch == 1 || value.dispatch == "1" ? true : false
							value.download = value.download == 1 || value.download == "1" ? true : false  
							value.print = value.print == 1 || value.print == "1" ? true : false
							value.update = value.update == 1 || value.update == "1" ? true : false  
							value.view = value.view == 1 || value.view == "1" ? true : false
						return value;
					}) 
					this.dataParams.setting_name = response.data.data.setting_name;
					// this.dataParams = response.data.data;  
				}
				else{
					var permission = this.dataParams.permission; 
					this.dataParams = response.data.data;
					this.dataParams.permission = permission; 
				}
			})
		},
		getIndex(module){
			for(var i = 0; i < this.dataParams.permission.length; i++){
				if(this.dataParams.permission[i].module == module){
					return i;
				}
			}
		},
		submit(){ 
			axios.post(config.api_path+'/permission', this.dataParams.permission)
			.then(response=>{
				this.getData();
				Swal.fire({
					title: 'Success!',
					text: "Permissions successfully updated.",
					icon: 'success', 
				})
			})
		}
	}
}
</script>
